import React from 'react';

const useWindowResize = (onResize: () => void) => {
  React.useEffect(() => {
    const listener = () => onResize();
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [onResize]);
};

export default useWindowResize;
