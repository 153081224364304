import React from 'react';
import useRefCallback from '~/utils/react/useRefCallback';

interface IntersectionObserverContextValue {
  /**
   * Notifies when the panel is occupying more space than any other panel.
   * Returns an unsubscribe handle.
   */
  subscribe: (el: HTMLElement, callback: () => void) => () => void;
  /**
   * Disables notifications until 500ms pass without any IntersectionObserver
   * events. This is used when the user clicks a tab button and we smooth-scroll
   * to the corresponding tab panel. We don't want selected-tab indicator to
   * react to this programmatic scrolling.
   */
  temporarilyDisable: () => void;
}

const useTemporarilyDisable = () => {
  const timeoutIdRef = React.useRef(undefined);
  return React.useMemo(
    () => ({
      disabled: () => timeoutIdRef.current !== undefined,
      temporarilyDisable: () => {
        if (timeoutIdRef.current !== undefined) {
          clearTimeout(timeoutIdRef.current);
        }
        timeoutIdRef.current = setTimeout(() => {
          timeoutIdRef.current = undefined;
        }, 500);
      },
    }),
    []
  );
};

/**
 * A context that lets you watch when a tab panel starts occupying more visible
 * area than other tab panels.
 */
export const IntersectionObserverContext = React.createContext<IntersectionObserverContextValue | undefined>(undefined);

const IntersectionObserverProvider = ({ rootMargin, children }: { rootMargin?: string; children: React.ReactNode }) => {
  /**
   * A map where for each panel container element, we store the size of its
   * visible area in pixels squared (when known) and a callback to be called
   * when it's occupying more space than any other panel.
   */
  const observedElements = useRefCallback(() => new Map<Element, { visibleAreaSize?: number; callback: () => void }>());
  const { disabled, temporarilyDisable } = useTemporarilyDisable();
  const intersectionObserver = useRefCallback(() =>
    typeof window !== 'undefined'
      ? new IntersectionObserver(
          (entries) => {
            // Update area sizes stored in observedElements.
            entries.forEach(({ target, intersectionRatio }) => {
              const observedElement = observedElements.get(target);
              if (observedElement) {
                observedElements.set(target, {
                  visibleAreaSize:
                    intersectionRatio * (target as HTMLElement).offsetWidth * (target as HTMLElement).offsetHeight,
                  callback: observedElement.callback,
                });
              }
            });
            if (disabled()) {
              // Reset the timer.
              temporarilyDisable();
            } else {
              const observedElementsValues = [...observedElements.values()];
              observedElementsValues.sort((a, b) => (b.visibleAreaSize ?? 0) - (a.visibleAreaSize ?? 0));
              if (
                observedElementsValues.length >= 2 &&
                (observedElementsValues[0].visibleAreaSize ?? 0) > (observedElementsValues[1].visibleAreaSize ?? 0)
              ) {
                observedElementsValues[0].callback();
              }
            }
          },
          {
            // Too lazy to write the code to generate this.
            threshold: [
              0,
              0.05,
              0.1,
              0.15,
              0.2,
              0.25,
              0.3,
              0.35,
              0.4,
              0.45,
              0.5,
              0.55,
              0.6,
              0.65,
              0.7,
              0.75,
              0.8,
              0.85,
              0.9,
              0.95,
              1,
            ],
            ...(rootMargin !== undefined ? { rootMargin } : {}),
          }
        )
      : undefined
  );

  return (
    <IntersectionObserverContext.Provider
      value={React.useMemo(
        () => ({
          subscribe: (el, callback) => {
            if (intersectionObserver === undefined) {
              return () => {};
            }
            observedElements.set(el, {
              callback,
            });
            intersectionObserver.observe(el);
            return () => {
              observedElements.delete(el);
              intersectionObserver.unobserve(el);
            };
          },
          temporarilyDisable,
        }),
        [intersectionObserver, observedElements, temporarilyDisable]
      )}
    >
      {children}
    </IntersectionObserverContext.Provider>
  );
};

/**
 * Provides {@link IntersectionObserverContext}.
 */
export default IntersectionObserverProvider;
