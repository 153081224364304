import React from 'react';
import { TabClickPubSubContext } from './TabClickPubSubProvider';
import { SelectedTabContext } from './SelectedTabProvider';
import ScrollIntoViewContext from '~/components/ScrollIntoViewContext';
import { IntersectionObserverContext } from './IntersectionObserverProvider';

const TabPanel = ({
  tabId,
  ...divProps
}: { tabId: string } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const { subscribe: subscribeTabClick } = React.useContext(TabClickPubSubContext);
  const { setTabId } = React.useContext(SelectedTabContext);
  const scrollIntoView = React.useContext(ScrollIntoViewContext);
  const { subscribe: subscribeIntersectionObserver, temporarilyDisable } = React.useContext(
    IntersectionObserverContext
  );
  const divRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const el = divRef.current;
    if (el) {
      return subscribeIntersectionObserver(el, () => {
        setTabId(tabId);
      });
    }
  }, [setTabId, tabId, subscribeIntersectionObserver]);
  React.useEffect(
    () =>
      subscribeTabClick((clickedTabId) => {
        if (clickedTabId === tabId) {
          setTabId(tabId);
          if (divRef.current) {
            temporarilyDisable();
            scrollIntoView(divRef.current);
          }
        }
      }),
    [subscribeTabClick, setTabId, tabId, scrollIntoView, temporarilyDisable]
  );
  return <div {...divProps} ref={divRef} />;
};

export default TabPanel;
