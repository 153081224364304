import { TabClickPubSubContext } from './TabClickPubSubProvider';
import { SelectedTabContext } from './SelectedTabProvider';
import React from 'react';
import TabButtonsControl, { TabButtonsControlProps } from '~/components/TabButtonsControl';

const TabButtons = (props: Omit<TabButtonsControlProps, 'onChange' | 'value'>) => {
  const { emit } = React.useContext(TabClickPubSubContext);
  const { tabId } = React.useContext(SelectedTabContext);
  return <TabButtonsControl {...props} onChange={emit} value={tabId} />;
};

export default TabButtons;
