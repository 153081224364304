import getSelectedTabProvider from './SelectedTabProvider';
import TabClickPubSubProvider from './TabClickPubSubProvider';
import IntersectionObserverProvider from './IntersectionObserverProvider';
import type ScrollIntoViewContext from '~/components/ScrollIntoViewContext';

/**
 * A factory for components that wire together descendant tab buttons and tab
 * panels. `rootMargin` is passed as an option to the IntersectionObserver
 * instance. You can configure the way a panel is scrolled into view using
 * {@link ScrollIntoViewContext}.
 */
const getScrollTabs = (defaultTabId: string, rootMargin?: string) => {
  const SelectedTabProvider = getSelectedTabProvider(defaultTabId);
  const ScrollTabs = ({ children }: { children: React.ReactNode }) => (
    <SelectedTabProvider>
      <TabClickPubSubProvider>
        <IntersectionObserverProvider {...{ rootMargin }}>{children}</IntersectionObserverProvider>
      </TabClickPubSubProvider>
    </SelectedTabProvider>
  );
  return ScrollTabs;
};

export default getScrollTabs;
export { default as TabButtons } from './TabButtons';
export { default as TabPanel } from './TabPanel';
