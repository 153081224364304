import React from 'react';

interface Callback {
  (tabId: string): void;
}

interface TabClickContextValue {
  emit: (tabId: string) => void;
  /**
   * Returns an unsubscribe handle.
   */
  subscribe: (callback: Callback) => () => void;
}

/**
 * A context that provides a pub-sub mechanism for tab button click events.
 */
export const TabClickPubSubContext = React.createContext<TabClickContextValue | undefined>(undefined);

const TabClickPubSubProvider = ({ children }: { children: React.ReactNode }) => {
  const subscribers = React.useRef(new Set<Callback>());
  return (
    <TabClickPubSubContext.Provider
      value={React.useMemo(
        () => ({
          emit: (tabId) => {
            subscribers.current.forEach((callback) => callback(tabId));
          },
          subscribe: (callback) => {
            subscribers.current.add(callback);
            return () => {
              subscribers.current.delete(callback);
            };
          },
        }),
        []
      )}
    >
      {children}
    </TabClickPubSubContext.Provider>
  );
};

/**
 * Provides {@link TabClickPubSubContext}.
 */
export default TabClickPubSubProvider;
