import React from 'react';

/**
 * Like useRef, but lets you pass initializer callback instead of actual value.
 *
 * Implements logic suggested here: https://github.com/facebook/react/issues/14490#issuecomment-454973512
 */
const useRefCallback = <T>(callback: () => T) => {
  const ref = React.useRef<T | undefined>(undefined);
  const value = ref.current;
  if (value !== undefined) {
    return ref.current;
  } else {
    const newValue = callback();
    ref.current = newValue;
    return newValue;
  }
};

export default useRefCallback;
