import React from 'react';

export interface SelectedTabContextValue {
  tabId: string;
  setTabId: (value: string) => void;
}

/**
 * A context that stores the currently selected tab.
 */
export const SelectedTabContext = React.createContext<SelectedTabContextValue | undefined>(undefined);

/**
 * A factory for components that provide {@link SelectedTabContext}.
 */
const getSelectedTabProvider = (defaultTabId: string) => {
  const SelectedTab = ({ children }: { children: React.ReactNode }) => {
    const [tabId, setTabId] = React.useState(defaultTabId);
    return <SelectedTabContext.Provider value={{ tabId, setTabId }}>{children}</SelectedTabContext.Provider>;
  };
  return SelectedTab;
};

export default getSelectedTabProvider;
