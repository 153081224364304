import styled from 'styled-components';
import React from 'react';
import { maxWidth } from '~/styles/media';
import useWindowResize from '~/hooks/useWindowResize';

const Container = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.lighterGrey};
  position: relative;
  background: ${(props) => props.theme.white};

  @media ${maxWidth('sm')} {
    overflow-x: auto;
    padding: 0 16px;

    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
`;

const TabButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 76px;
  padding: 0;
  font-family: 'Calibre';
  font-weight: 500;
  font-size: 18px;
  background: none;
  cursor: pointer;
  white-space: nowrap;

  & + & {
    margin-left: 40px;
  }
`;

const BottomBar = styled.div<{ $left: number; $width: number }>`
  position: absolute;
  bottom: 0;
  height: 6px;
  left: ${(props) => props.$left}px;
  width: ${(props) => props.$width}px;
  background: currentColor;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export interface TabButtonsControlTab {
  label: string;
  id: string;
}

export type TabButtonsControlProps = {
  tabs: TabButtonsControlTab[];
  value: string;
  onChange: (id: string) => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>;

const TabButtonsControl = ({ tabs, value, onChange, ...rest }: TabButtonsControlProps) => {
  const [bottomBarPosition, setBottomBarPosition] = React.useState<{ left: number; width: number } | undefined>(
    undefined
  );
  const [lastScrolledTabIndex, setLastScrollTabIndex] = React.useState<number | undefined>();
  const selectedTabRef = React.useRef<HTMLElement | null>(null);
  const updateBottomBarPosition = () => {
    const el = selectedTabRef.current;
    if (el) {
      const newPosition = { left: el.offsetLeft, width: el.offsetWidth };
      setBottomBarPosition((oldPosition) =>
        oldPosition?.left === newPosition.left && oldPosition?.width === newPosition.width ? oldPosition : newPosition
      );
    }
  };
  useWindowResize(updateBottomBarPosition);
  return (
    <Container role="tablist" {...rest}>
      {tabs.map(({ label, id }, index) => (
        <TabButton
          ref={
            id === value
              ? (el) => {
                  if (el) {
                    selectedTabRef.current = el;
                    updateBottomBarPosition();
                    // Cannot just use el.scrollIntoView because we only want
                    // horizontal scroll, even if the tabs are below the fold.
                    //
                    // When a user hits a tab to the right of the selected tab,
                    // try scrolling in such a way that the newly selected tab
                    // is at the start of the visible area, and the same logic
                    // for the opposite direction.
                    const container = el.parentElement;
                    if (lastScrolledTabIndex === undefined || lastScrolledTabIndex < index) {
                      const firstTabEl = container.firstChild as HTMLElement;
                      const paddingLeft = firstTabEl.offsetLeft;
                      container.scrollTo({ left: el.offsetLeft - paddingLeft, behavior: 'smooth' });
                    } else if (lastScrolledTabIndex !== undefined && lastScrolledTabIndex > index) {
                      const lastTabEl = container.lastChild.previousSibling as HTMLElement;
                      const paddingRight = container.scrollWidth - (lastTabEl.offsetLeft + lastTabEl.clientWidth);
                      container.scrollTo({
                        left: el.offsetLeft + el.offsetWidth - container.clientWidth + paddingRight,
                        behavior: 'smooth',
                      });
                    }
                    setLastScrollTabIndex(index);
                  }
                }
              : undefined
          }
          role="tab"
          aria-selected={id === value ? 'true' : 'false'}
          onClick={() => onChange(id)}
          key={id}
        >
          {label}
        </TabButton>
      ))}
      {bottomBarPosition !== undefined && <BottomBar $left={bottomBarPosition.left} $width={bottomBarPosition.width} />}
    </Container>
  );
};

export default TabButtonsControl;
