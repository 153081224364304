import React from 'react';

/**
 * A context for configuring how we scroll an element into view - useful when
 * due to elements overlaying the scrollable container, native `scrollIntoView`
 * doesn't cut it. The value is a function that takes the element to scroll to.
 */
const ScrollIntoViewContext = React.createContext<(el: HTMLElement) => void>((el) =>
  el.scrollIntoView({ behavior: 'smooth' })
);

export default ScrollIntoViewContext;
